import { Link, graphql, useStaticQuery } from "gatsby";
import React, { useEffect } from 'react';
import Parser from 'react-html-parser';
import Header from '../../components/Header/Header-2';
import Layout from '../../components/Layout/Layout';
import Seo from "../../components/seo";
import { isSSR, submitActiveCampaignForm, updatePageContent } from '../../utils/utils';
const getdata = graphql`
{
  wpgraphql {
    posts(where: {categoryName: "Software"}, first: 10) {
      nodes {
        slug
        title
        id
        content
        date
        categories {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            mediaItemUrl
          }
        }
      }
    }
    page(id: "cG9zdDo5ODMw") {
      id
      title
      content
      uri
      seo {
        canonical
        metaDesc
        metaKeywords
        focuskw
        title
        twitterTitle
        twitterDescription
        opengraphDescription
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphTitle
        opengraphType
        opengraphImage {
          sourceUrl
        }
      }    
    }
  }
}
`
const Software = () => {
  const data = useStaticQuery(getdata);
  const common = data.wpgraphql.page;
  const latestPost = data.wpgraphql.posts.nodes;
  useEffect(() => {
    // load OptinMonster
    if (window.om210904_195537) {
      window.om210904_195537.reset();
    }
    // 
    document.addEventListener('om.Campaign.parseCampaigns', function(event) {
      const email = document.getElementById('eminence-field-email').value;
      // submit campaign
      if (email) {
        const data = { name: email, email: email };
        submitActiveCampaignForm(data, false);
      }
    });
  });
  return (
    <Layout>
      <Seo title={common.title} description={common.seo.metaDesc} canonical={ `${common.uri}`} seo={common.seo}  />
      <Header home={false} menu='info' />
      <main>
        <section class="press-banner-title py-5">
          <div class="container">
            <h2 class="page-title text-white">Patent Information</h2>
          </div>
        </section>

        <section className='py-5'>
        <div className="container">
          <div className='row'>
            <div className='col-md-12'>
              <h2 className='display-5 mb-3 border-bottom d-inline-block pb-3'>{ common.title }</h2>
                <div className='mb-5'>
                  <article>
                    <>
                      <div className="accordion-content">
                      {Parser(isSSR() ? common.content : updatePageContent(common.content, latestPost))}
                      </div>
                    </>
                  </article>
                </div>
            </div>
          </div>

          {/* <div className='row'>
            <div className='col-md-12'>
              <h4>The Latest 10 US Patents On Software As Of { todayStr() }</h4>
              <ul>
              {
                latestPost.nodes.map((node, index) => (
                  <li>
                    <Link to={"/blog/" + node.slug}>
                      {node.title}
                    </Link>
                  </li>
                ))
              }
              </ul>
            </div>
          </div> */}

          <div className='row'>
            <div className='col-md-12'>
              <p><small>
                Also checkout the latest patents on <Link to="/industries/3-d-printing/">3-D Printing</Link>, <Link to="/industries/electronics/">Electronics</Link>, 
                &nbsp;<Link to="/industries/communications/">Communication</Link>,  <Link to="/industries/blockchain-and-fintech/">Blockchain and Fintech</Link>,
                &nbsp;<Link to="/industries/artificial-intelligence-ai/">Artificial Intelligence (AI)</Link>,  <Link to="/industries/nanotechnology/">Nanotechnology</Link>,
                &nbsp;<Link to="/industries/autonomous-vehicles/">Autonomous Vehicles</Link>, and <Link to="/industries/metaverse/">Metaverse</Link>
                </small>
              </p>
            </div>
          </div>
        </div>
      </section>
      </main>

    </Layout>
  );
};

export default Software;
